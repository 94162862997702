<template>
    <div>
        <LoadingComponent :isLoading="loading" />
        <div class="shadow-lg rounded-lg bg-white flex items-center p-5 mb-3">
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-5">
                    <label class="text-gray-400">
                        用戶 手機
                        <div class="flex">
                            <div class="phone-select">
                                <el-select v-model="filterOpts.phone_country" size="small" placeholder="请选择">
                                    <el-option v-for="item in phoneOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                                </el-select>
                            </div>
                            <div>
                                <el-input v-model="filterOpts.phone" size="small" clearable> </el-input>
                            </div>
                        </div>
                    </label>
                </div>
                <div class="col-span-4">
                    <label class="text-gray-400">
                        申請日期 範圍
                        <el-date-picker
                            v-model="dateInputs"
                            size="small"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="開始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </label>
                </div>
                <div class="col-span-3 flex items-end">
                    <button class="orange-btn-800 md:text-sm text-xs mr-1 w-[73px] h-[30px] flex-1" @click="goSearch">搜尋</button>
                    <button class="black-btn-outline md:text-sm text-xs w-[73px] h-[30px] flex-1" @click="reset">重置</button>
                </div>
            </div>
        </div>
        <div class="overflow-x-auto bg-white">
            <table class="myTable">
                <thead>
                    <tr>
                        <th>用戶 暱稱</th>
                        <th>階段</th>
                        <th>申請時間</th>
                        <th>查看</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in datas" :key="index" class="text-center duration-200 transition">
                        <td>{{ item.user.name }}</td>
                        <td>{{ item.level }}</td>
                        <td>{{ item.created_at | formatDateTime }}</td>
                        <td class="cursor-pointer" @click="goEdit(item.user_id)">
                            <i class="far fa-edit"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="justify-center mt-3 flex mb-3">
                <MyPagination
                    :paginationData="paginationData"
                    :currentPage="currentPage"
                    @onPageSizeChange="pageSizeChange"
                    @onCurrentPageChange="currentPageChange" />
            </div>
        </div>
    </div>
</template>

<script>
// 分頁組件
import MyPagination from "@/components/MyPagination.vue";
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
// options phone
import { phoneOptions } from "@/config/optionsConfig.js";
// init search Filter options
const defaultFilterOpts = {
    phone_country: "886",
    phone: "",
    status: "",
    created_at_start: "",
    created_at_end: "",
};
export default {
    name: "WaitForCheck",
    components: {
        LoadingComponent,
        MyPagination,
    },
    computed: {
        // 統整 getlist api filters
        filterData() {
            const request = { ...this.filterOpts };
            // 過濾空值
            if (!request.created_at_start) {
                delete request.created_at_start;
                delete request.created_at_end;
            }
            // const phone = this.filterOpts.phone ? `${this.filterOpts.phone_country}${this.filterOpts.phone}` : "";
            return {
                ...request,
                // phone: phone,
                status: [0],
                level: [1, 2],
                limit: this.paginationData.limit,
                page: this.currentPage,
            };
        },
        // 手機國碼
        phoneOptions() {
            return phoneOptions;
        },
    },
    data() {
        return {
            loading: true,
            datas: [],
            paginationData: {
                limit: 10,
                total: 0,
            },
            currentPage: 1,
            filterOpts: { ...defaultFilterOpts },
            dateInputs: [], // 時間範圍
        };
    },
    methods: {
        async getList() {
            this.loading = true;
            const { status, data } = await this.$api
                .SearchAuthenticationsApi(this.filterData)
                .then((res) => {
                    return { data: res.data, status: res.status };
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "取得資料失敗",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
            if (status === 200) {
                this.datas = [...data.data];
                this.paginationData = {
                    limit: data.per_page,
                    total: data.total,
                };
            }
        },
        goSearch() {
            this.currentPage = 1;
            this.getList();
        },
        reset() {
            this.dateInputs = [];
            this.filterOpts = { ...defaultFilterOpts };
            this.currentPage = 1;
            this.paginationData = {
                limit: 10,
                total: 0,
            };
            this.getList();
        },
        pageSizeChange(val) {
            // 更新每頁取得幾筆資料參數
            this.paginationData.limit = val;
            // 當前頁面設定為第一頁
            this.currentPage = 1;
            this.getList();
        },
        currentPageChange(val) {
            // 設定目前分頁為第幾面
            this.currentPage = val;
            this.getList();
        },
        goEdit(id) {
            this.$router.push({ name: "verification_detail", params: { id } });
        },
    },
    watch: {
        dateInputs(val) {
            if (val === null || val.length === 0) {
                this.filterOpts.created_at_start = "";
                this.filterOpts.created_at_end = "";
                return;
            }
            this.filterOpts.created_at_start = val[0];
            this.filterOpts.created_at_end = val[1];
        },
        "filterOpts.phone"(val) {
            if (this.$isEmpty(val)) {
                return;
            }
            if (val[0] == 0 && val[1] == 9) {
                this.filterOpts.phone = val.substring(1);
                return;
            }
        },
    },
    mounted() {
        this.getList();
    },
};
</script>

<style lang="scss" scoped>
.myTable {
    width: 100%;
    tbody {
        tr .show-ellipsis {
            max-width: 100px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

label {
    color: #757575;
    font-size: 16px;
    font-weight: 400;
    line-height: 34px;
}

.phone-select {
    width: 150px;
    margin-right: 5px;
}

::v-deep .el-input__inner {
    max-width: 100%;
}

::v-deep .el-range-separator {
    width: 20px;
}
</style>
